

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  margin-bottom: 100px;
}

@import "./components/Footer";
@import "./components/MethodSettings";
@import "./components/newCourseAssetModal";
@import "./components/resourcePreview";
@import "./components/resourceUpload";
@import "./components/searchResult";
@import "./components/Sidebar";
@import "./components/DataStructures/index";
@import "./components/inputs/index";

@import "./containers/index"
